.carousel-item {
  position: relative;
}

.carousel-img {
  height: 450px;
  object-fit: cover;
  width: 100vw;
}

.carousel-caption {
  position: absolute;
  bottom: 10%;
  /* left: 50%;
  transform: translateX(-50%); */
  color: white;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.5);
  /* Fondo oscuro semitransparente */
  padding: 10px;
  border-radius: 5px;
}

.slick-next {
  right: 0;
  visibility: hidden;
}