.body:before {
  content: "";
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: #2a5555;
  opacity: 0.7;
  z-index: 1;
}

video.bg-video {
  position: fixed;
  top: 50%;
  left: 50%;
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  transform: translateX(-50%) translateY(-50%);
  z-index: 0;
}

.masthead {
  position: relative;
  overflow: hidden;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
}

.masthead:before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.85);
}

.masthead .masthead-content {
  position: relative;
  max-width: 40rem;
  padding-top: 5rem;
  padding-bottom: 5rem;
}

.masthead .masthead-content h1,
.masthead .masthead-content .h1 {
  font-size: 2.5rem;
}

.masthead .masthead-content p {
  font-size: 1.2rem;
}

.masthead .masthead-content p strong {
  font-weight: 700;
}

.masthead .masthead-content .input-group-newsletter input {
  height: auto;
  width: 100%;
  font-size: 1rem;
  padding: 1rem;
}

.masthead .masthead-content .input-group-newsletter button {
  font-size: 0.85rem;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 1px;
  padding: calc(1rem + 2px);
}

/* marca articulos (JD)*/
.mrk {
  width: auto;
  color: white;
  background-color: rgb(13, 110, 253);
  font-size: 0.8em;
  font-weight: 700;
  padding: 7px 20px;
  border-radius: 100px;
}

@media (min-width: 992px) {
  .masthead {
    height: 100%;
    width: 75vw;
    min-height: 0;
    padding-bottom: 0;
    position: absolute;
  }

  .masthead:before {
    transform: skewX(-9deg);
    transform-origin: top right;
  }

  .masthead .masthead-content {
    padding-top: 0;
    padding-bottom: 0;
    padding-left: 2rem;
    padding-right: 9rem;
  }

  .masthead .masthead-content h1,
  .masthead .masthead-content .h1 {
    font-size: 3.5rem;
  }

  .masthead .masthead-content p {
    font-size: 1.3rem;
  }
}

@media (min-width: 1200px) {
  .masthead {
    width: 65vw;
  }
}

.social-icons {
  position: relative;
  z-index: 2;
}

.social-icons .btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  height: 4rem;
  width: 4rem;
  border-radius: 100rem;
}

@media (min-width: 992px) {
  .social-icons {
    position: absolute;
    height: 100%;
    top: 0;
    right: 2.5rem;
    width: auto;
  }
}