
.card-galeria{
  width: 290px;
  max-height: 410px;
}

.card.hovered {
  transition: transform 0.3s, cursor 0.3s;
  transform: scale(1.1);
  cursor: pointer;
  border: 3px solid aqua;;
}

/* Ajusta estos estilos según tus necesidades */
.row {
  display: flex;
  flex-wrap: wrap;
}

.col-lg-3 {
  transition: transform 0.4s, cursor 0.4s, background-color 0.4s, border 0.4s;
}

.hovered {
  transform: scale(1.2);
  cursor: pointer;
  /* background-color: #f0f0f0; */
  border: 3px solid aqua;
  z-index: 300;
}

@media (max-width: 768px) {
  .card-galeria{
    width: 100%;
    height: 400px;
  }
}