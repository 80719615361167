:root {
  --color-primary: #070024;
}

.update-item {
  background-image: linear-gradient(
    to right,
    #54b3d6,
    #54b3d6 50%,
    #ffffff 50%
  );
  background-size: 200% 100%;
  background-position: -100%;
  display: inline-block;
  position: relative;
  -webkit-background-clip: text;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
}

.update-item:before {
  content: "";
  background: #54b3d6;
  display: block;
  position: absolute;
  bottom: -3px;
  left: 0;
  width: 0;
  height: 3px;
  transition: all 0.3s ease-in-out;
}

.update-item:hover {
  background-position: 0;
}

.update-item:hover::before {
  width: 100%;
}

/* /------------------------- */

.table-responsive {
  position: relative;
  max-height: calc(100vh - 100px);
  overflow-y: auto;
}

.table thead th {
  position: sticky;
  top: 0;
  background-color: #fff; /* Color de fondo del encabezado */
  font-size: 13px !important;
}

.table tbody tr td {
  font-size: 11px !important;
}

th {
  resize: horizontal;
  overflow: auto;
  min-width: 50px;
}
