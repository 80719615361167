:root {
  --color-primario: #972DA8; /* Cambia este valor al color primario que prefieras */
  --color-secundario: #0035FF; /* Cambia este valor al color secundario que prefieras */
}

body {
  --bs-primary: var(--color-primario) !important;
  --bs-secondary: var(--color-secundario) !important;
}

